import { useState } from "react";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline"; // Importing the arrow icons
import Image from "./image/salah.jpg"; // Ensure the correct path to your image

const HoverSection = () => {
  return (
    <div
      className="relative flex flex-col lg:flex-row items-center lg:gap-32  bg-cover bg-center h-screen text-white"
      style={{ backgroundImage: `url(${Image})` }}
    >
      <div
        className="absolute inset-0 bg-gradient-to-b from-[#553c65] to-transparent"
        style={{ opacity: 0.6 }} // Adjust opacity as needed
      />

      {/* Left Section */}
      <div className="flex flex-col justify-center w-full max-w-lg text-center m-10 lg:text-left lg:w-1/3">
        <h1 className="text-4xl md:text-5xl font-bold z-10">OUR WAY</h1>
        <p className="mt-4 text-lg md:text-2xl font-bold z-10">
          We assess the needs of the individual, community, and tailor our
          intervention to achieve the results they desire.
        </p>
      </div>

      {/* Arrows Section (hidden on small screens) */}
      <div className="flex justify-center lg:justify-center">
        <ChevronDoubleRightIcon className="w-6 h-6 sm:w-10 sm:h-10 text-white absolute top-1/2 transform -translate-y-1/2 group-hover:opacity-0 transition-opacity duration-300 hidden lg:block" />
      </div>

      {/* Right Section with hover effect */}
      <div className="text-white space-y-10 flex flex-col items-center justify-center w-full max-w-lg p-4 lg:w-2/4 lg:items-start">
        {[
          {
            title: "Interdisciplinary Collaboration",
            description:
              "Working as a team of professionals in psychology, social work, gender expert, M&E experts, and research,<br/> to deliver integrated care, reducing fragmentation and duplicate services.",
          },
          {
            title: "Contextualized and accessible care",
            description:
              "Developing and delivering support that is culturally appropriate and relevant to the needs and environment,<br/> respecting and responding to the diverse backgrounds of individuals and communities.",
          },
          {
            title: "Sustainable Systems Of Care",
            description:
              "Including communities in the delivery of programs allows us to engage target communities,<br/> to build strong support networks, and to create sustainable systems of care for the long-term.",
          },
          {
            title: "Empowering Communities",
            description:
              "We aim to empower individuals and communities to take an active role in their own care and decision-making processes.",
          },
          {
            title: "Results",
            description:
              "Increase access and reduce disparities in care, enhanced self-sufficiency, and create sustainable systems of care that have a long-lasting impact.",
          },
        ].map((item, index) => (
          <div
            key={index}
            className="group relative text-left p-2" // Add shadow effect and rounded corners
          >
            <h2 className="text-lg sm:text-xl md:text-2xl font-bold cursor-pointer relative inline-block">
              <span>{item.title}</span>
            </h2>
            <p
              className="text-sm sm:text-base md:text-lg leading-relaxed opacity-0 max-h-0 group-hover:opacity-100 group-hover:max-h-screen group-hover:mt-2 transition-all duration-500 ease-in-out overflow-hidden"
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HoverSection;
