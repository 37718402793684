import { ChevronDoubleRightIcon } from "@heroicons/react/24/outline"; // Importing the arrow icons
import ImageB from "./image/IMG_0428.JPG";

export default function OurVision() {
  return (
    <div
      className="relative flex flex-col lg:flex-row items-center lg:gap-28  bg-cover bg-center h-screen text-white"
      style={{ backgroundImage: `url(${ImageB})` }}
    >
     <div
        className="absolute inset-0 bg-gradient-to-b from-[#553c65] to-transparent"
        style={{ opacity: 0.6 }} // Adjust opacity as needed
      />

      {/* Left Section */}
      <div className="flex flex-col justify-center w-full max-w-lg text-center lg:relative left-24 m-16 lg:text-left lg:w-1/3">
        <h1 className="text-4xl md:text-5xl font-bold z-10">OUR VISION</h1>
      </div>

      {/* Arrows Section (hidden on small screens) */}
      <div className="flex justify-center lg:justify-center">
        <ChevronDoubleRightIcon className="w-6 h-6 sm:w-10 sm:h-10 text-white absolute top-1/2 transform -translate-y-1/2 group-hover:opacity-0 transition-opacity duration-300 hidden lg:block" />
      </div>

      {/* Right Section with hover effect */}
      <div className="text-white space-y-8 flex flex-col items-center justify-center w-full max-w-lg p-4 lg:w-2/4 lg:items-start">
        {[
          {
            title: "Fostering inclusivity",
            description:
              "community-led approach to creating health and social infrastructures",
          },
          {
            title: "Facilitating access",
            description:
              "to education and socio-economic mobility and participation through capacity building and psychology",
          },
          {
            title: "Decreasing dependencies",
            description:
              "and cultivating self-determination, harm reduction, and sustainable socio-political transformation through holistic mental health care and social support.",
          },
        ].map((item, index) => (
          <div
            key={index}
            className="group relative text-left p-2" // Add shadow effect and rounded corners
          >
            <h2 className="text-lg sm:text-xl md:text-2xl font-bold cursor-pointer relative inline-block">
              <span>{item.title}</span>
            </h2>
            <p
              className="text-sm sm:text-base md:text-lg leading-relaxed opacity-0 max-h-0 group-hover:opacity-100 group-hover:max-h-screen group-hover:mt-2 transition-all duration-500 ease-in-out overflow-hidden"
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
