import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LinkIcon, VideoCameraIcon } from "@heroicons/react/24/outline";

export default function Blog() {
  const [blog, setBlog] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [dialogType, setDialogType] = useState("image"); // Type of content for dialog
  const [page, setPage] = useState(1);
  const itemsPerPage = 6;
  const totalPages = Math.ceil(blog?.length / itemsPerPage);

  const fetchBlog = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API}blogs`
      );
      setBlog(response.data.blogs);
    } catch (error) {
      console.error("Error fetching blog:", error);
    }
  };

  useEffect(() => {
    fetchBlog();
  }, []);

  const isImageFile = (file) => file?.match(/\.(jpeg|jpg|gif|png)$/i);
  const isVideoFile = (file) => file?.match(/\.(mp4|mov|wmv|avi|mkv)$/i);

  const handleOpenDialog = (content, type = "image") => {
    setDialogContent(content);
    setDialogType(type);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogContent(null);
  };

  const getVisibleBlogs = () => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return blog?.slice(startIndex, endIndex);
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const truncateDescription = (text) => {
    return text?.length > 35 ? `${text.substring(0, 35)}...` : text;
  };

  return (
    <div className="flex flex-col items-center p-8 sm:p-10 lg:p-12 pb-16">
      <h1 className="text-3xl font-bold text-center mb-8">Blog</h1>

      <div className="w-full flex flex-wrap justify-center gap-6">
        {getVisibleBlogs()?.map((info) => (
          <div
            key={info.id}
            className="w-full sm:w-full md:w-3/4 lg:w-1/4 bg-white shadow-lg rounded-lg p-6 mb-8 flex flex-col justify-between"
          >
            {info?.file && isImageFile(info.file) && (
              <img
                src={info.file}
                alt={info.title}
                className="w-full h-40 object-cover shadow-lg mt-1 cursor-pointer mb-4"
                onClick={() => handleOpenDialog(info.file, "image")}
              />
            )}
            {info?.file && isVideoFile(info.file) && (
              <div
                className="relative w-full h-40 bg-black cursor-pointer shadow-lg mt-1 mb-4"
                onClick={() => handleOpenDialog(info.file, "video")}
              >
                <VideoCameraIcon className="absolute inset-0 m-auto text-white h-12 w-12" />
              </div>
            )}

            <div className="text-left">
              <h3 className="text-lg font-bold mb-2">{info?.title}</h3>
              <p className="text-sm text-gray-600 mb-2">
                {info?.description && info.description?.length > 35
                  ? (
                      <>
                        {truncateDescription(info.description)}
                        <button
                          className="text-blue-500 ml-2"
                          onClick={() => handleOpenDialog(info.description, "text")}
                        >
                          Read More
                        </button>
                      </>
                    )
                  : info?.description}
              </p>
            </div>

            <div className="flex justify-start mt-4">
              <button
                className={`bg-[#3FD0A2] text-white font-bold py-2 px-4 rounded-full text-xs sm:text-sm flex items-center gap-2 ${
                  !info?.link ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={() => info?.link && window.open(info.link, "_blank")}
                disabled={!info?.link}
              >
                <LinkIcon className="h-5 w-5" />
                {info?.link ? "Read more" : "No Link Available"}
              </button>
            </div>
            <div className="border-t border-gray-300 mt-4 pt-2">
              <p className="text-xs text-gray-500">
                {new Date(info?.createdAt).toLocaleDateString()}
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-center items-center mt-8">
        <button
          onClick={handlePreviousPage}
          disabled={page === 1}
          className="text-blue-500 hover:text-blue-700 text-lg px-4"
        >
          « Previous
        </button>
        <span className="mx-4 text-gray-600">
          Page {page} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={page === totalPages}
          className="text-red-500 hover:text-red-700 text-lg px-4"
        >
          Next »
        </button>
      </div>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogActions>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent>
          {dialogType === "image" && (
            <img src={dialogContent} alt="Preview" className="w-full h-auto" />
          )}
          {dialogType === "video" && (
            <video controls className="w-full h-auto">
              <source src={dialogContent} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
          {dialogType === "text" && (
            <p className="text-base text-gray-700">{dialogContent}</p>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
